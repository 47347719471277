import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { ColumnLayout } from '../components/layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PlayableStationLogo from '../components/PlayableStationLogo'
import LinkButton from '../components/LinkButton'

import noMusic from '../assets/images/no-music.svg'

export default class NotFoundPage extends React.Component {
  componentDidMount() {
    console.log('not found: location =', this.props.location)
  }
  render() {
    const { data } = this.props
    const stations = data.allFeaturedStation.edges.map(edge => edge.node)
    return (
      <React.Fragment>
        <ColumnLayout alignItems={'center'} itemSpacing={'4rem'}>
          <img src={noMusic} width={96} style={{ paddingTop: '4rem' }} alt="" />
          <Header
            title={'404'}
            subtitle={
              "You've reached a page that doesn't exist.\nCheck out some of our trending stations to break the silence."
            }
            paddingTop={'0rem'}
          >
            <Grid>
              {stations.map((station, index) => (
                <SquareGridItem key={index}>
                  <PlayableStationLogo
                    stationID={station.mount_id}
                    stationName={station.station_name}
                    stationLogoURL={getLogo(station)}
                    size={'100px'}
                    stationURL={station.fields.url}
                  />
                </SquareGridItem>
              ))}
            </Grid>
            <LinkButton to={'/listen'} color={'blue'}>
              Discover More
            </LinkButton>
          </Header>
        </ColumnLayout>
        <Footer />
      </React.Fragment>
    )
  }
}

export const query = graphql`
  query {
    allFeaturedStation: allStation(
      filter: { fields: { isFeatured: { eq: true } } }
      limit: 8
    ) {
      edges {
        node {
          mount_id
          logo
          station_name
          fields {
            url
            isCurated
            logoFile {
              childImageSharp {
                fixed(
                  width: 100
                  height: 100
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`

function getLogo(station) {
  if (station.fields.logoFile && station.fields.logoFile.childImageSharp) {
    return station.fields.logoFile.childImageSharp
  } else {
    return station.logo
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  column-gap: 1rem;
  row-gap: 1rem;
  max-height: calc(500px + 4rem);
  @media (min-width: 350px) {
    grid-template-columns: repeat(3, 100px);
  }
  @media (min-width: 500px) {
    grid-template-columns: repeat(4, 100px);
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(6, 100px);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(8, 100px);
  }
`

const SquareGridItem = styled.div``
