import React from 'react'
import GatsbyImage from 'gatsby-image'
import Image from 'react-image'
import styled from 'styled-components'

import PlayerContextConsumer from './state/PlayerContext'
import PlayingIndicator from './PlayingIndicator'

export default ({
  stationID,
  stationName,
  stationLogoURL,
  stationURL,
  size,
  withShadow
}) => (
  <PlayerContextConsumer>
    {({ session, updateSession }) => {
      const isCurrentSession = session.stationID === stationID
      const isPlaying = isCurrentSession && session.isPlaying
      return (
        <Wrapper
          onClick={() => {
            updateSession({ stationID, stationURL })
          }}
          size={size}
          withShadow={true}
        >
          {typeof stationLogoURL.fixed === 'object' ? (
            <StyledGatsbyImage
              fixed={stationLogoURL.fixed}
              critical={true}
              alt={stationName}
              size={size}
            />
          ) : (
            <StyledImage
              src={stationLogoURL}
              alt={stationName}
              loader={<Placeholder size={size} />}
              unloader={<Placeholder size={size} />}
              size={size}
            />
          )}
          <Overlay show={isCurrentSession}>
            {<PlayingIndicator isPlaying={isPlaying} />}
          </Overlay>
        </Wrapper>
      )
    }}
  </PlayerContextConsumer>
)

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  width: ${props => props.size};
  height: ${props => props.size};
  box-shadow: ${props =>
    props.withShadow ? '0 2px 28px 4px rgba(0, 0, 0, .1)' : 'none'};
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  & img {
    background-color: #fff;
  }
  & img::selection {
    background-color: transparent;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  & img {
    background-color: #fff;
  }
  & img::selection {
    background-color: transparent;
  }
`

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${props => (props.show ? '1' : '0')};
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms ease;
  &:hover {
    opacity: 1;
  }
`

const Placeholder = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  background-color: rgba(255, 255, 255, 0.5);
`
